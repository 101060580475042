<template>
  <div class="outer">
    <img :src="imgList.zhutu" alt="" class="bigPicture" />
    <div class="navList">
      <div
        v-for="(item, index) in $t('industryService.IS_navList')"
        :key="index"
        class="navItem"
        @click="goAnchor('#anchor-' + index, index)"
      >
        <div class="name">
          {{ item.name }}
        </div>
        <div
          class="xian"
          v-if="index < $t('industryService.IS_navList').length - 1"
        ></div>
      </div>
    </div>

    <div class="outerTwo" id="app-root" ref="app_root">
      <div class="titleOne">{{ $t("industryService.IS_titleOne") }}</div>
      <div
        v-for="(item, index) in $t('industryService.IS_modular')"
        :key="index"
        class="ISmodu"
        :id="'anchor-' + index"
        @click="modular(item)"
      >
        <div class="xian"></div>
        <div class="modular">
          <div class="modularCont">
            <div class="tip">{{ item.tip }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="see">
              <div>{{ item.see }}</div>
              <img src="@/assets/img/checklan.png" alt="" />
            </div>
          </div>
          <div class="modularImg">
            <img :src="item.bjImg" alt="" class="imgHoverBig" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-16 11:02
 */
export default {
  data() {
    return {
      imgList: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.getBigPicture();
  },
  methods: {
    /**
     * 锚点跳转
     * 刘嘉鑫
     * 2022-8-16
     */
    goAnchor(selector, index) {
      // this.$el.querySelector(selector).scrollIntoView();
      this.$nextTick(() => {
        this.$el.querySelector(selector).scrollIntoView({
          behavior: "smooth", //定义动画过渡效果"auto"或 "smooth" 之一。默认为 "auto"。
          block: "center", //定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"。
          inline: "nearest", //"start", "center", "end", 或 "nearest"之一。默认为 "nearest"。
        });
      });
    },

    /**
     * 模块跳转
     * 刘嘉鑫
     * 2022-8-16
     */
    modular(item) {
      this.$router.push({
        path: item.path,
      });
    },

    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-19
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgCenter",
        data: {
          type: 1, // 类型:1=行业服务,2=行业科普,3=人才招聘,4=关于我们,5=技术研发
        },
      }).then((res) => {
        console.log("大图", res);
        this.imgList = res.img_list;
        this.$t("industryService.IS_modular")[0].bjImg = res.img_list.yanffw;
        this.$t("industryService.IS_modular")[1].bjImg = res.img_list.rencpy;
        this.$t("industryService.IS_modular")[2].bjImg = res.img_list.zhuanlfw;
        this.$t("industryService.IS_modular")[3].bjImg = res.img_list.xueshd;
        this.$t("industryService.IS_modular")[4].bjImg = res.img_list.hangykp;
      });
    },
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-8-23
     */
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$("#anchor-0").offset().top - 750) {
        this.$("#anchor-0 .modularCont").addClass("showDiv");
        this.$("#anchor-0 .xian").addClass("showDiv");
        this.$("#anchor-0 .modularImg>img").addClass("showDiv");
      }
      if (this.currentScroll >= this.$("#anchor-1").offset().top - 750) {
        this.$("#anchor-1 .modularCont").addClass("showDiv");
        this.$("#anchor-1 .xian").addClass("showDiv");
        this.$("#anchor-1 .modularImg>img").addClass("showDiv");
      }
      if (this.currentScroll >= this.$("#anchor-2").offset().top - 750) {
        this.$("#anchor-2 .modularCont").addClass("showDiv");
        this.$("#anchor-2 .xian").addClass("showDiv");
        this.$("#anchor-2 .modularImg>img").addClass("showDiv");
      }
      if (this.currentScroll >= this.$("#anchor-3").offset().top - 750) {
        this.$("#anchor-3 .modularCont").addClass("showDiv");
        this.$("#anchor-3 .xian").addClass("showDiv");
        this.$("#anchor-3 .modularImg>img").addClass("showDiv");
      }
      if (this.currentScroll >= this.$("#anchor-4").offset().top - 700) {
        this.$("#anchor-4 .modularCont").addClass("showDiv");
        this.$("#anchor-4 .xian").addClass("showDiv");
        this.$("#anchor-4 .modularImg>img").addClass("showDiv");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px 60px;
  background: #ffffff;
}

.bigPicture {
  width: 100%;
  height: 600px;
}

.navList {
  background: #f6f6f6;
  display: flex;
  align-items: center;

  .navItem {
    padding: 20px 0;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }

  .name {
    font-size: 16px;
    font-family: FZFengYaSongS-GB;
    font-weight: 400;
    color: #222222;
    margin: 0 60px;
  }

  .xian {
    width: 2px;
    height: 15px;
    background: #cccccc;
    margin: 5px 0 0 0;
  }
}

.outerTwo {
  padding: 80px 50px 0;

  .titleOne {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    line-height: 32px;
    margin-bottom: 80px;
  }

  .ISmodu {
    margin-bottom: 80px;
    cursor: pointer;
    .xian {
      width: 20px;
      height: 4px;
      background: #1a2a60;
      margin-bottom: 62px;
      overflow: hidden;
      transition: all 0.4s ease-out 0s;
      transform: translate(-50px, 0px);
      opacity: 0;
    }

    .modular {
      display: flex;
      justify-content: space-between;
      .modularImg {
        overflow: hidden;
        width: 460px;
        height: 280px;
        margin-left: 130px;
        > img {
          width: 100%;
          height: 100%;

          overflow: hidden;
          transition: all 1s ease-out 0s;
          transform: translate(100%, 0px);
          opacity: 0;
        }
      }

      > .modularCont {
        display: flex;
        flex-direction: column;
        padding: 20px 0 57px;
        overflow: hidden;
        transition: all 0.8s ease-out 0s;
        transform: translate(-100px, 0px);
        opacity: 0;
        .tip {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #222222;
          margin-bottom: 30px;
        }
        .title {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
          margin-bottom: 20px;
        }

        .see {
          display: flex;
          align-items: center;
          > div {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #1a2a60;
            margin-right: 8px;
          }
          > img {
            width: 20px;
            height: 14px;
          }
        }
      }
    }
    .modular:hover {
      .see {
        > img {
          animation: checkImg 0.7s linear infinite;
          position: relative;
        }
      }
    }
  }
}
</style>